import React from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';

import ImageGrid from 'components/ImageGrid';
import Layout from '../components/Layout';
import SEO from '../components/seo';

import styles from './index.module.scss';

const IndexPage = () => {
  return (
    <Layout className={styles.page}>
      <SEO title="Home" />
      <section className="content mb--xl">
        <h1 className={cx(styles.mainPageTitle, 'title')}>
          We are GEWS.
        </h1>
        <div>
          <h2 className={styles.pageTitle}>
          River of Blessing Foundation (ROBF) runs a non-profit primary school called River of Blessing Academy. 
          The school is ten (10) years old. 
          In preparation for a smooth transition to secondary school especially for our girls, as well as other brilliant yet at-risk girls in Africa, 
          we wish to seek for funds to build a residential laboratory Waldorf secondary school for years 7-12. 
          This school will take a new name the Green Earth Waldorf School (GE-WS).
          <br />
          <br />
          This special predominantly girls secondary school will spearhead a "save the planet” 
          campaign through engaging African girls with -non-discriminatory-equal access to 
          quality formal education and life skills training opportunities.  
          </h2>
        </div>
        <div className="videoWrapper">
          <iframe
            title="Welcome to GEWS Africa"
            className="youtubeVideo"
            src="https://www.youtube.com/embed/kyinfZbI0kc"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        </div>
      </section>
      <section>
        <ul className={cx(styles.threeUpWrapper)}>
          <li className={styles.threeUpCard}>
            <Link to="/programs">
              <h3>Curriculums</h3>
              <span>Read more...</span>
            </Link>
          </li>
          {/* <li className={styles.threeUpCard}>
            <Link to="/products">
              <h3>Products</h3>
              <span>We build tech to drive change</span>
            </Link>
          </li> */}
          <li className={styles.threeUpCard}>
            <Link to="/donate">
              <h3>Support</h3>
              <span>Donate to support our mission</span>
            </Link>
          </li>
        </ul>
      </section>
      <br />
      <section className={cx(styles.imageGrid)}>
        <ImageGrid />
      </section>
    </Layout>
  );
};

export default IndexPage;
