import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import cx from 'classnames';
import styles from './ImageGrid.module.scss';

export const ImageGrid = () => {
  return (
    <div className={cx(styles.gridContainer)}>
      <StaticImage
        src="../../_assets/images/home-page-grid/classrooms.png"
        alt="ARCHITECTURAL AND STRUCTURAL DRAWINGS OF A TWELVE CLASSROOMS UNIT STOREY BUILDING"
        className={styles.gridItem1}
      />
      <StaticImage
        src="../../_assets/images/home-page-grid/manifest.jpg"
        alt="ROBA 6year old student reading a manifest at a..."
        className={styles.gridItem2}
      />
      <StaticImage
        src="../../_assets/images/home-page-grid/main-dorm.png"
        alt="Main Dorm View"
        className={styles.gridItem3}
      />
      <StaticImage
        src="../../_assets/images/home-page-grid/classrooms-plan.png"
        alt="ARCHITECTURAL AND STRUCTURAL DRAWINGS OF A TWELVE CLASSROOMS UNIT STOREY BUILDING"
        className={styles.gridItem4}
      />
    </div>
  );
};
